








































































import Vue from "vue";
import { Action } from "vuex-class";
import api from "@/core/utils/api";
import { HowToVideo } from "@/core/models";
import { Component, Prop } from "vue-property-decorator";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

@Component({ components: { VideoPlayer } })
export default class VideoTiem extends Vue {
  @Prop({ default: () => {} }) video!: HowToVideo;
  @Action("displaySnackbar") displaySnackbar!: (msg: string) => void;
  dialog = false;
  loading = false;
  editing = false;
  newTitle = this.video.title;
  newDescription = this.video.description;

  get id() {
    const { id } = this.video;
    return `how-to-video-${id}`;
  }
  get url() {
    // @ts-ignore
    return this.video.video.uri;
  }

  async edit() {
    this.loading = true;
    try {
      const end = "/api/Showcase";
      const data = {
        id: this.video.id,
        title: this.newTitle,
        description: this.newDescription,
      };
      await api.put(end, data);
      const msg = this.$t("snack.vid.editsuccess").toString();
      this.displaySnackbar(msg);
      this.$emit("edited", data);
      this.editing = false;
    } catch (error) {
      const msg = this.$t("snack.vid.editfail").toString();
      this.displaySnackbar(msg);
    }
    this.loading = false;
  }

  async remove() {
    this.loading = true;
    try {
      const id = this.video.id;
      const end = `/api/Showcase/${id}`;
      await api.delete(end);
      const msg = this.$t("snack.vid.delsuccess").toString();
      this.displaySnackbar(msg);
      this.$emit("delete", id);
      this.dialog = false;
    } catch (error) {
      const msg = this.$t("snack.vid.delfail").toString();
      this.displaySnackbar(msg);
    }
    this.loading = false;
  }
}
