






































import Vue from "vue";
import api from "@/core/utils/api";
import { HowToVideo } from "@/core/models";
import { Component } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { Action } from "vuex-class";

@Component({
  mixins: [validationMixin],
  validations: {
    title: { required, min: minLength(2) },
    description: { required, min: minLength(2) },
  },
})
export default class CreateVideo extends Vue {
  @Action("displaySnackbar") displaySnackbar!: (msg: string) => void;
  dialog = false;
  loading = false;
  valid = false;
  title = "";
  description = "";
  file: File | null = null;

  async submit() {
    if (this.loading) return;
    if (!this.file) {
      const msg = this.$t("forms.file").toString();
      this.displaySnackbar(msg);
      return;
    }

    if (this.$v.$invalid) {
      const msg = this.$t("forms.invaliddata").toString();
      this.displaySnackbar(msg);
      return;
    }

    this.loading = true;
    try {
      const end = "/api/Showcase";
      const data = new FormData();
      data.append("title", this.title);
      data.append("description", this.description);
      data.append("file", this.file);
      const res = (await api.post(end, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })) as unknown;
      this.$emit("created", res as HowToVideo);
    } catch (error) {
      console.log(error);
      this.displaySnackbar(error);
    }
    this.loading = false;
  }
}
