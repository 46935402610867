































import Vue from "vue";
import { Component } from "vue-property-decorator";

import api from "@/core/utils/api";
import { HowToVideo } from "@/core/models";
import VideoItem from "../components/VideoItem.vue";
import CreateVideo from "../components/CreateVideo.vue";

@Component({ components: { CreateVideo, VideoItem } })
export default class HowToVideos extends Vue {
  loading = false;
  videos: HowToVideo[] = [];
  async getVideos() {
    this.loading = true;
    try {
      const end = "/api/Showcase";
      const res = (await api.get(end)) as HowToVideo[];
      this.videos = res;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  onCreated(video: HowToVideo) {
    this.videos.push(video);
  }
  onEditVideo(data: any) {
    const { id, title, description } = data;
    const idx = this.videos.findIndex(x => x.id === id);
    if (idx === -1) return;

    this.videos[idx].title = title;
    this.videos[idx].description = description;
  }
  onDeleteVideo(id: number) {
    const idx = this.videos.findIndex(x => x.id === id);
    if (idx === -1) return;
    this.videos.splice(idx, 1);
  }
  created() {
    this.getVideos();
  }
}
